<template >
    <div>

<ProjectHeader></ProjectHeader>
    <section class="inner-page portfolio-details" v-if="componentData">
      <div class="container">

        <div class="row gy-4">

          <div class="col-lg-12">
            <div class="portfolio-description">
              <h2>{{componentData.header}}</h2>
              <span v-html="componentData.details"></span>
              
            </div>
          </div>

         

        </div>

      </div>







    </section>
    <section class="inner-page portfolio-details counts values" v-if="componentData && componentData.inerventions.length">
      <div class="container" >
<div class="portfolio-description ">
        <h2>Intervention highlight</h2>
        
        <!-- <p>
          The BRIDGES Programme has four focus areas or “bridges” that connect target participants to further
          opportunities for jobs and enterprise development.
        </p> -->
        <div class="row ">
            
          <div :class="{ 'col-lg-12 col-md-12': componentData.inerventions.length == 1 } " class="col-lg-6 col-md-6"  v-for="(inervention,index) in componentData.inerventions" :key="index">
            <div class=" box card component-item col-md-12">
              
              <p class="horizontal-center col-md-12">
                <img :src="inervention.image" alt="" width="250px" style="height: -webkit-fill-available;">
              </p>
            

                  <h3 class="text-center col-md-12"><span class=" col-md-12">{{inervention.header}}</span></h3>
                  <ul class="text-left" style="    list-style: none">
                    
                    
                    <li><strong>Sector</strong>: 
                    <span v-for="(sector,index) in inervention.inerventionSectors" :key="index"> <span v-if="index != inervention.inerventionSectors.length - 1">{{sector.sector.header}},</span> <span v-if="index == inervention.inerventionSectors.length - 1">{{sector.sector.header}}</span> </span>
                    </li>
                    <li><strong>Regions</strong>: {{inervention.regions}}</li>
                    <br>
                    <span v-html="inervention.details"></span>
                      
                  </ul>
               
              </div>
            </div>
          

            


        </div>
        </div>
      </div>
      
    </section>
    <br>
    <br>
    </div>
</template>
<script>
import { getComponentData, getFieldById } from '../../assets/js/service';
import ProjectHeader from './Project.vue'
export default {
  components:{
  ProjectHeader
},
    data() {
      return {
        componentData:null,
      }
    },
      watch: {
    "$store.state.componentData": {
      handler() {
        this.componentData = this.$store.state.componentData;
      },
    },
  },
    mounted() {
    
    

        this.componentData = this.$store.state.componentData;
        
       
     
    },
    methods: {
      routeTo(route){
      this.$router.push(route);
    },
    },
}
</script>
<style >
    
</style>