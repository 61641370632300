<template >
    <div v-if="projectData">
            <section class="breadcrumbs" :style="'background-color:#'+projectData.theme">
      <div class="container">

        <ol>
          <li><a @click="routeTo('home')">Home</a></li>
          <li><a @click="routeTo('/our_work')">Our Works</a> </li>
          <li><a @click="routeTo('/our_work')">{{projectData.header}}</a> </li>
          <li v-if="componentsData && this.$route.name == 'components'">{{componentsData.header}}</li>
         

        </ol>
        <img :src="projectData.header_image" alt="" width="100%">


      </div>
    </section><!-- End Breadcrumbs -->

    <router-view ></router-view>

    </div>
</template>
<script>
import { getComponentData, getFieldById } from '../../assets/js/service';
export default {
    data() {
      return {
        projectData : null,
        componentsData : this.$store.state.componentData 
      }
    },
    watch: {
    "$store.state.componentData": {
      handler() {
     
        this.componentData = this.$store.state.componentData;
      },
    },
      watch: {
    "$store.state.projectInformation": {
      handler() {
        this.projectData = this.$store.state.projectInformation;
      },
    },
  },
    },
    mounted() {
      let token = localStorage.getItem('token');

      if(this.$store.state.projectInformation){
        this.projectData = this.$store.state.projectInformation;
      }
      
      else if(this.$route.params.id && !this.$route.path.toString().startsWith('/components/')){
        getFieldById('Projects',this.$route.params.id,token,'components').then(
          resp=>{
            this.$store.commit("set",["projectInformation",resp.data]);
            this.projectData = resp.data;
          })
      }else if(this.$route.path.toString().startsWith('/components/')){
         getComponentData('Components',token,this.$route.params.id,{main:'inerventions',sub:"inerventionSectors",last:"sector"}).then(resp=>{
          
          this.$store.commit("set",["componentData",resp.data]);
           getFieldById('Projects',resp.data.projectId,token,'components').then(
          resp=>{
            this.projectData = resp.data;
            this.$store.commit("set",["projectInformation",resp.data]);
            
          })
        })
      }
      
      else{
        this.$router.push('/our_work');
      }
      
    },
    
    methods: {
      routeTo(route){
      this.$router.push(route);
    },
    },
}
</script>
<style >
   
</style>